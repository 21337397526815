import { Outlet } from "@remix-run/react";

export default function AuthenticationPage() {
  return (
    <div className="overflow-hidden relative h-full flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-6 lg:px-0">
      <div className="relative lg:col-span-2 hidden h-full flex-col lg:flex dark:border-r">
        <img
          src="https://images.unsplash.com/photo-1638202993928-7267aad84c31"
          alt="Authentication"
          className="object-cover h-full"
        />
      </div>
      <div className="lg:p-8 lg:col-span-4">
        <Outlet />
      </div>
    </div>
  );
}
